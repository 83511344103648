import React from 'react';
import ReactDOM from 'react-dom';
import App, { LoginView } from './App';
import './index.css';

class Blah extends React.Component{

   render(){
       return (
            <div>
          <ul>
         <li>Home</li>
    </ul>
           </div>
       );
   }
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
