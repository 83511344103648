import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import Recaptcha from 'react-google-recaptcha';
import { Nav, Navbar,  NavDropdown, Container, Row, Form, Button, Col, Modal, Image } from 'react-bootstrap';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

const POLARIS_CAM_URL = 'zm/';


export default class App extends Component {
  constructor (props) {
    super(props);

    this.state = {authenticated: false, username: undefined, password: undefined, showPasswordChange: false};

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.openPasswordChangeView = this.openPasswordChangeView.bind(this);
    this.closePasswordChangeView = this.closePasswordChangeView.bind(this);
    this.campusName = this.getCampusName();

    this.init();

  }
  async init () {
    await this.getLogin();
    this.getMonitors();
  }

  getCampusName () {
    var subDomain = window.location.hostname.split('.')[0];

    if (subDomain.toLocaleLowerCase() === "west-meridian") {
      return "West-Meridian";
    }
    return subDomain.charAt(0).toUpperCase() + subDomain.slice(1);
  }

  async getUserId() {
    try {
      var response = await axios.get(POLARIS_CAM_URL + '?view=logout')

        var match = /view=user.*?uid=(\d+)/.exec(response.data);
        var uid = match[1];
        // console.log('uid: ' + uid);
        return uid;
    } catch (e) {
      console.log(`Failed to get UserId: ${e}`);
      return null;
    }
  }

  getAuthToken () {
    if (this.state.loginRes) {
      return this.state.loginRes.access_token;
    }

    return null;
  }

  async getMonitors () {
    try {
      var token = this.getAuthToken();
      var response =  await axios.get(`${POLARIS_CAM_URL}api/monitors.json?token=${token}`)
        console.log('Successfully received monitors!');
        var monitors = response.data.monitors.map(monitor => { return {name: monitor.Monitor.Name, id: monitor.Monitor.Id}; });
        this.setState({
          monitors: monitors,
          activeMonitor: monitors[Math.floor(Math.random() * (monitors.length - 1))].id,
          authenticated: true
        });
    } catch (error) {
      console.log(`Failed to get monitors: ${error}`);
      this.logout();
    }
  }

  async getLogin() {
    try {
      var response = await axios.get(`${POLARIS_CAM_URL}api/host/login.json`)
      debugger;
      this.setState({
        loginRes:response.data,
        uid: await this.getUserId()
      })
    } catch (e) {
      console.log(e);
    }
  }

  async login (username, password, reCaptchaKey) {
    const data = new FormData();

    if (!reCaptchaKey) {
      return null;
    }


    try {
      data.append('user', username);
      data.append('pass', password);
      data.append('stateful', '1')
      var response = await axios.post(`${POLARIS_CAM_URL}api/host/login.json`, data)
      console.log('Login Succeeded!');
        this.setState({
          username: username,
          password: password,
          authenticated: true,
          loginRes: response.data,
          uid: await this.getUserId()
        });
        this.getMonitors();
    } catch (e) {
      this.setState({authenticated: false});
      console.log(`Failed to login: ${e}`);
    }
  }

  async logout () {
    try {
      axios.post(`${POLARIS_CAM_URL}api/host/logout.json`)
      this.setState({authenticated: false});
      console.log('Logout Successded!');
    } catch (e) {
      console.log(`Logout failed: ${e}`);
    }
  }

  openPasswordChangeView () {
    this.setState({showPasswordChange: true});
  }

  closePasswordChangeView (changed) {
    this.setState({showPasswordChange: false});
    if (changed) {
      this.logout();
    }
  }

  updateActiveMonitor (monitorId) {
    this.setState({activeMonitor: monitorId})
  }

  getMonitorStreamLink (monitorId) {
    var rand = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    var token = this.getAuthToken();
    return `${POLARIS_CAM_URL}cgi-bin/nph-zms?mode=jpeg&scale=100&maxfps=14&buffer=1000&monitor=${monitorId}&rand=${rand}&token=${token}`;
  }

  render () {
    return (
      <div className='App'>


          <Navbar bg="light" expand="lg" collapseOnSelect>
              <Container>
              <Navbar.Brand>
              <a className="navbar-brand" href="https://www.polarislearning.net">Polaris Learning Center - {this.campusName}</a>
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            { this.state.authenticated ? <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                { this.state.monitors ? <NavDropdown eventKey={1} title="Kids Cam" id="kids-cam-dropdown">
                {this.state.monitors.map(monitor => <NavDropdown.Item key={monitor.id} active={this.state.activeMonitor === monitor.id} onClick={() => this.updateActiveMonitor(monitor.id)}>{monitor.name}</NavDropdown.Item>)}
                </NavDropdown> : null }
              </Nav>
              <Nav >
                  <Nav.Link onClick={this.openPasswordChangeView} href='#'>Change Password</Nav.Link>
                  <Nav.Link onClick={this.logout} href='#'>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>: null }
            </Container>
          </Navbar>

          { this.state.authenticated ? <Container><Row><Col xs={12}>
          {(this.state.activeMonitor !== null && this.state.activeMonitor !== undefined) ?
            <Image className='camera-view justify-content-md-center' src={this.getMonitorStreamLink(this.state.activeMonitor)} alt='active-monitor' responsive /> : null}
          </Col></Row></Container>
          : <LoginView login={this.login} /> }
          <ChangePasswordView show={this.state.showPasswordChange} uid={this.state.uid} closePasswordChangeView={this.closePasswordChangeView} />
      </div>
    );
  }
}

export class LoginView extends React.Component {
  constructor (props) {
    super(props);

    this.state = {reCaptchaKey: undefined};

    this.recaptcha = undefined;

    this.username = undefined;
    this.password = undefined;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyReCaptcha = this.verifyReCaptcha.bind(this);
  }

  handleChange (event) {
    const name = event.target.name;
    const value = event.target.value;
    this[name] = value;
  }

  handleSubmit (event) {
    this.props.login(this.username, this.password, this.state.reCaptchaKey);

    event.preventDefault();
  }

  verifyReCaptcha (key) {
    // console.log('key: ' + key);
    this.setState({reCaptchaKey: key});
  }


  render () {
    return (
      <Col className={'login'} xs={{span: 6, offset:3}} >
        <Form onSubmit={this.handleSubmit}>
          <Form.Group as={Row} controlId='login-screen'>
              <Form.Label column sm="2">
                Username
              </Form.Label>
            <Col sm="10">

              <Form.Control type='text' onChange={this.handleChange} placeholder='Enter Username' name='username' required />
              </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
                Password
            </Form.Label>
            <Col sm="10">
              <Form.Control type='password' onChange={this.handleChange} placeholder='Enter Password' name='password' required />
              </Col>

          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={{span: 10, offset: 2}}>
              <Recaptcha
                ref='captcha'
                sitekey='6Lci1CEUAAAAAGDfAAZ59fTRV6skZeqRrPFkLciF'
                onChange={this.verifyReCaptcha}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={{span: 10, offset: 2}} >
              <Button type='submit' disabled={!this.state.reCaptchaKey}>Login</Button>
            </Col>
          </Form.Group>
        </Form>
      </Col>

    );
  }
}

export class ChangePasswordView extends React.Component {
  constructor (props) {
    super(props);

    this.state = {validated: false, showError: false, failedError: false };

    this.newPassword = undefined;
    this.confirmPassword = undefined;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange (event) {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'new-password') {
      this.newPassword = value;
    } else if (name === 'confirm-password') {
      this.confirmPassword = value;
    }

    if (this.newPassword !== this.confirmPassword) {
      this.setState({showError: true});
    } else {
      this.setState({showError: false});
    }
  }

  handleSubmit (event) {
    const data = new FormData();

        data.append('action', 'user');
        data.append('uid', this.props.uid);
        data.append('newUser[Password]', this.newPassword);

        const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });

    axios.post(`${POLARIS_CAM_URL}index.php`, data)
      .then(response => {
        console.log('Password change succeeded!');
        this.setState({failedError: false});
        this.props.closePasswordChangeView(true);
      }).catch(error => {
        console.log(`Password change failed: ${error}`);
        this.setState({failedError: true});
      });

    event.preventDefault();
  }

  render () {
    return (
        <Modal {...this.props} centered
        aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form validated={this.state.validated}>
            <Form.Row>
              <Form.Group as={Col} controlId='change-password-screen'>
                  <Form.Label column sm={2}>
                    New Password
                  </Form.Label>
                <Col sm={10}>
                  <Form.Control type='password' onChange={this.handleChange} placeholder='New Password' name='new-password' required />
                </Col>
              </Form.Group>
              </Form.Row>
              <Form.Row>
              <Form.Group as={Col} >
                  <Form.Label column sm={2}>
                    Confirm Password
                  </Form.Label>
                <Col sm={10}>
                  < Form.Control isInvalid={this.state.showError} type='password' onChange={this.handleChange} placeholder='Confirm Password' name='confirm-password' required />
                </Col>
                <Form.Control.Feedback type="invalid">
                Password does not match
              </Form.Control.Feedback>
              </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit' onClick={this.handleSubmit} disabled={this.state.showError && this.newPassword !== ''} value='Update'>Update</Button>
            <Button type='button' onClick={() => this.props.closePasswordChangeView(false)} value='cancel'>Cancel</Button>
          </Modal.Footer>
        </Modal>
    );
  }
}
